var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"品牌添加"}},[_c('validation-observer',{ref:"createProdBrand"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-align":"center","label":"分类名称","label-cols":"1","label-cols-md":"3","content-cols":"6","content-cols-md":"8"}},[_c('validation-provider',{attrs:{"name":"分类名称","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"请输入分类名称"},model:{value:(_vm.editData.title),callback:function ($$v) {_vm.$set(_vm.editData, "title", $$v)},expression:"editData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-align":"center","label":"分类状态","label-cols":"1","label-cols-md":"3","content-cols":"6","content-cols-md":"8"}},[_c('b-form-radio-group',{attrs:{"options":[
                {
                  text: '启用',
                  value: 1,
                },
                {
                  text: '禁用',
                  value: 0,
                } ]},model:{value:(_vm.editData.status),callback:function ($$v) {_vm.$set(_vm.editData, "status", $$v)},expression:"editData.status"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.isRequest},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" 提交 ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }