<!-- @format -->
<template>
  <b-card title="品牌添加">
    <validation-observer ref="createProdBrand">
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="分类名称" label-cols="1" label-cols-md="3" content-cols="6" content-cols-md="8">
              <validation-provider #default="{ errors }" name="分类名称" rules="required">
                <b-form-input v-model="editData.title" :state="errors.length > 0 ? false : null" placeholder="请输入分类名称" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="分类状态" label-cols="1" label-cols-md="3" content-cols="6" content-cols-md="8">
              <b-form-radio-group
                v-model="editData.status"
                :options="[
                  {
                    text: '启用',
                    value: 1,
                  },
                  {
                    text: '禁用',
                    value: 0,
                  },
                ]"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" type="submit" @click.prevent="validationForm" :disabled="isRequest"> 提交 </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { reactive, ref, onUnmounted, getCurrentInstance } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'

import { required, url } from '@core/utils/validations/validations'

import store from '@/store'
import ClassManagerModule from './ClassManagerModule'

export default {
  name: 'BrandCreate',
  components: {
    BCard,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'classification-module'
    const { router } = useRouter()
    const { proxy } = getCurrentInstance()

    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, ClassManagerModule)
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
    const isRequest = ref(false)
    const editData = reactive({
      title: '',
      status: 1,
    })
    const createProdBrand = ref(null)

    const validationForm = () => {
      isRequest.value = true
      createProdBrand.value
        .validate()
        .then(res => {
          if (res) {
            store.dispatch('classification-module/createInvoices', { ...editData }).then(response => {
              if (response.code == 0) {
                proxy.$bvToast.toast('添加成功', {
                  title: '提示',
                  variant: 'success',
                  solid: true,
                })
                setTimeout(() => {
                  isRequest.value = false
                  router.back()
                }, 2000)
              }
            })
          } else {
            isRequest.value = false
          }
        })
        .catch(() => {
          isRequest.value = false
          console.log('validationForm error')
        })
    }

    return {
      validationForm,

      editData,
      createProdBrand,

      isRequest,
      required,
      url,
    }
  },
}
</script>

<style scoped></style>
